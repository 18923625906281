import GuestLayout from '@/Layouts/GuestLayout';
import { Head, Link, useForm } from '@inertiajs/react';
import { FormEventHandler } from 'react';


import { Button } from '@/components/ui/button';

export default function Login({
                                status,
                                canResetPassword
                              }: {
  status?: string;
  canResetPassword: boolean;
}) {
  const { data, setData, post, processing, errors, reset } = useForm({
    email: 'hej@hej.dk',
    password: 'hejhejhej',
    remember: false
  });

  const submit: FormEventHandler = (e) => {
    e.preventDefault();

    post(route('login.store'), {
      onFinish: () => reset('password')
    });
  };

  return (
    <GuestLayout>
      <Head title='Log in' />

      {status && (
        <div className='mb-4 text-sm font-medium text-green-600'>
          {status}
        </div>
      )}

      <div className='w-full lg:grid sm:min-h-[400px] lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[400px]'>
        <div className='flex items-center justify-center py-2'>
          <div className='mx-auto grid gap-6'>
            <div className='mx-auto mb-4'>
              <img
                src={`/img/logo_dark.svg`}
                alt='Image'
                width='150'
                // height='1080'
                className='object-cover dark:brightness-[0.2] dark:grayscale'
              />
            </div>
            <div className='grid gap-2 text-center'>
              <h1 className='text-3xl font-bold'>Login</h1>
              <p className='text-balance text-muted-foreground'>
                Please choose your preferred login provider
              </p>
            </div>
            <div className='grid gap-4'>
              {/*<div className="grid gap-2">
                                <Label htmlFor="email">Email</Label>
                                <Input
                                    id="email"
                                    type="email"
                                    placeholder="m@example.com"
                                    required
                                />
                            </div>
                            <div className="grid gap-2">
                                <div className="flex items-center">
                                    <Label htmlFor="password">Password</Label>
                                    <Link
                                        href="/forgot-password"
                                        className="ml-auto inline-block text-sm underline"
                                    >
                                        Forgot your password?
                                    </Link>
                                </div>
                                <Input
                                    id="password"
                                    type="password"
                                    required />
                            </div>
                            <Button
                                type="submit"
                                className="w-full">
                                Login
                            </Button>*/}
              <Button
                onClick={submit}
                variant='outline'
                className='w-full'>
                Login with Google
              </Button>
              <Button
                variant='outline'
                className='w-full'>
                Login with Microsoft
              </Button>
              <Button
                variant='outline'
                className='w-full'>
                Login with linkedIn
              </Button>
            </div>
            <div className='mt-4 text-center text-sm'>
              Don&apos;t have an account?{' '}
              <Link
                href='#'
                className='underline'>
                Contact sales
              </Link>
            </div>
          </div>
        </div>
        <div className='hidden bg-muted lg:block'>
          <img
            src={`/img/insects/${Math.floor(Math.random() * 4) + 1}.jpg`}
            alt='Image'
            // width='1920'
            // height='1080'
            className='object-cover dark:brightness-[0.2] dark:grayscale'
          />
        </div>
      </div>
    </GuestLayout>
  );
}
